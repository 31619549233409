import React, { useEffect, useState, useContext } from "react"

// Import sun and moon icons
import MdSunny from "react-ionicons/lib/MdSunny"
import MdMoon from "react-ionicons/lib/MdMoon"

// Import custom toggle css
import "./ThemeToggle.css"
import { ThemeContext } from "./ThemeContext"

const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext)

  const [firstTime, setFirstTime] = useState(false)

  return (
    <div className="flex items-center relative">
      <div
        onClick={() => setTheme(theme === "light" ? "dark" : "light")}
        className="toggle-button"
      >
        {theme === "light" ? (
          <MdSunny className="fill-current hover:text-gray-800"></MdSunny>
        ) : (
          <MdMoon className="fill-current hover:text-gray-200"></MdMoon>
        )}
      </div>
      {firstTime && theme === "light" ? (
        <div className="speech-bubble-container">
          <p className="speech-bubble text-xs text-white w-32 px-2 py-1">
            Dude, my eyes!!!
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ThemeToggle
