/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./general/Footer"
import Socials from "./general/Socials"
import { ThemeProvider } from "./general/ThemeContext"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider>
      <div className="p-8 lg:p-4 bg-white dark:bg-dark-bg-900 text-gray-600 dark:text-dark-bg-200 min-h-screen font-sans relative">
        <div className="max-w-screen-md mx-auto">
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <main>{children}</main>
          <Footer></Footer>
        </div>
        <Socials></Socials>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
