import React, { useContext } from "react"

// Import social icons
import Github from "react-ionicons/lib/LogoGithub"
import Linkedin from "react-ionicons/lib/LogoLinkedin"
import Twitter from "react-ionicons/lib/LogoTwitter"
import Email from "react-ionicons/lib/MdMail"

// Import static query for socials
import { useStaticQuery, graphql, Link } from "gatsby"
import { ThemeContext } from "./ThemeContext"

import GatsbyImage from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          socials {
            twitter
            github
            linkedin
            email
          }
        }
      }

      darkImage: file(relativePath: { eq: "Mike_Blanco_Rosa.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            tracedSVG
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }

      lightImage: file(relativePath: { eq: "Mike_Negro.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            tracedSVG
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }

      resume: file(relativePath: { eq: "resume.pdf" }) {
        publicURL
      }
    }
  `)

  const { theme } = useContext(ThemeContext)

  return (
    <footer className="border-t p-4 text-gray-800 dark:text-white">
      <GatsbyImage
        fluid={
          theme === "light"
            ? data.lightImage.childImageSharp.fluid
            : data.darkImage.childImageSharp.fluid
        }
        className="w-32 h-32 mx-auto"
      ></GatsbyImage>
      <ul className="flex max-w-xs justify-between m-0 mx-auto mb-3 p-0 text-sm font-bold">
        <li>
          <Link to="/work">Work</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <a href={data.resume.publicURL} download>
            Resume
          </a>
        </li>
      </ul>
      <ul className="flex w-32 justify-between m-0 mx-auto mb-4 p-0">
        <li>
          <a
            href={`https://github.com/${data.site.siteMetadata.socials.github}`}
            target="_blank"
            rel="noreferrer"
            rel="noreferrer"
          >
            <Github className="fill-current"></Github>
          </a>
        </li>
        <li>
          <a
            href={`https://linkedin.com/in/${data.site.siteMetadata.socials.linkedin}`}
            target="_blank"
            rel="noreferrer"
            rel="noreferrer"
          >
            <Linkedin className="fill-current"></Linkedin>
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/${data.site.siteMetadata.socials.twitter}`}
            target="_blank"
            rel="noreferrer"
            rel="noreferrer"
          >
            <Twitter className="fill-current"></Twitter>
          </a>
        </li>
        <li>
          <a
            href={`mailto:${data.site.siteMetadata.socials.email}`}
            target="_blank"
            rel="noreferrer"
            rel="noreferrer"
          >
            <Email className="fill-current"></Email>
          </a>
        </li>
      </ul>
      <p className="text-sm text-center">
        Built with love,{" "}
        <a
          href="https://www.tailwindcss.com"
          target="_blank"
          rel="noreferrer"
          rel="noreferrer"
          className="underline"
        >
          Tailwind
        </a>{" "}
        and{" "}
        <a
          href="https://www.gatsbyjs.com"
          target="_blank"
          rel="noreferrer"
          rel="noreferrer"
          className="underline"
        >
          Gatsby
        </a>
      </p>
      <p className="text-sm font-light mb-4 text-center">
        <Link to="/" className="font-bold text-gray-900 dark:text-white">
          Miguel Gallardo
        </Link>{" "}
        © {new Date().getFullYear()}
      </p>
    </footer>
  )
}

export default Footer
