import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ThemeToggle from "./general/ThemeToggle"

const Header = ({ siteTitle }) => {
  return (
    <nav className="py-2 md:py-4 mb-4 flex justify-between items-center">
      <div className="flex">
        <ul className="p-0 m-0 items-baseline text-base font-normal flex">
          <li className="m-0">
            <Link to="/">Home</Link>
          </li>
          <li className="m-0 ml-6">
            <Link to="/work">Work</Link>
          </li>
          <li className="m-0 ml-6">
            <Link to="/about">About</Link>
          </li>
        </ul>
      </div>
      <ul className="flex p-0 m-0 flex-wrap items-center">
        <li className="m-0">
          <ThemeToggle></ThemeToggle>
        </li>
      </ul>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
