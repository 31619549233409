import React, { createContext, useEffect, useState } from "react"

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const getColorMode = () => {
    const persistedColorPreference = window.localStorage.getItem("theme")
    const hasPersistedPreference = typeof persistedColorPreference === "string"
    // If the user has explicitly chosen light or dark,
    // let's use it. Otherwise, this value will be null.
    if (hasPersistedPreference) {
      return persistedColorPreference
    }
    // If they haven't been explicit, let's check the media
    // query
    const mql = window.matchMedia("(prefers-color-scheme: dark)")
    const hasMediaQueryPreference = typeof mql.matches === "boolean"
    if (hasMediaQueryPreference) {
      return mql.matches ? "dark" : "light"
    }
    // If they are using a browser/OS that doesn't support
    // color themes, let's default to 'light'.
    return "light"
  }

  const [currentTheme, setCurrentTheme] = useState(null)

  useEffect(() => {
    const mode = getColorMode()

    setTheme(mode)
  }, [])

  const setTheme = value => {
    // In case you call it with the current value
    if (currentTheme === value) return

    // Change classes in body
    document.body.classList.remove(currentTheme)
    document.body.classList.add(value)

    // Set the current theme
    setCurrentTheme(value)

    // Persist it on update
    window.localStorage.setItem("theme", value)
  }

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
