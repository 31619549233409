import React from "react"

// Import social icons
import Github from "react-ionicons/lib/LogoGithub"
import Linkedin from "react-ionicons/lib/LogoLinkedin"
import Twitter from "react-ionicons/lib/LogoTwitter"
import Email from "react-ionicons/lib/MdMail"

import { useStaticQuery, graphql } from "gatsby"

import "./Socials.css"

const Socials = () => {
  const data = useStaticQuery(graphql`
    query SocialsQuery {
      site {
        siteMetadata {
          socials {
            twitter
            github
            linkedin
            email
          }
        }
      }
    }
  `)

  return (
    <ul className="fixed left-0 hidden lg:block px-12 py-8 m-0 socials bottom-0 text-gray-700 dark:text-brand-400">
      <li className="mb-3">
        <a
          href={`https://github.com/${data.site.siteMetadata.socials.github}`}
          target="_blank"
          rel="noreferrer"
        >
          <Github className="fill-current"></Github>
        </a>
      </li>
      <li className="mb-3">
        <a
          href={`https://linkedin.com/in/${data.site.siteMetadata.socials.linkedin}`}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="fill-current"></Linkedin>
        </a>
      </li>
      <li className="mb-3">
        <a
          href={`https://twitter.com/${data.site.siteMetadata.socials.twitter}`}
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className="fill-current"></Twitter>
        </a>
      </li>
      <li className="mb-3">
        <a
          href={`mailto:${data.site.siteMetadata.socials.email}`}
          target="_blank"
          rel="noreferrer"
        >
          <Email className="fill-current"></Email>
        </a>
      </li>
    </ul>
  )
}

export default Socials
